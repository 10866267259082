<template>
  <van-pull-refresh v-model="isLoading" pulling-text="Tarik ke bawah untuk refresh..."
    loosing-text="Lepaskan untuk refresh..." success-text="Refresh Berhasil" loading-text=" " @refresh="onRefresh">
    <div class="content">
      <div class="top">
        <div class="title_img">
          <h4 class="name">
            <span>Pasar pinjaman</span>
            <span @click="visibleLogOut = true">Keluar</span>
          </h4>
          <div class="Semua_orabg_meminjam">
            <div>Semua orang meminjam</div>
            <span>Dapat mengajukan beberapa produk sekaligus</span>
          </div>
        </div>

        <div class="top_bg">
          <van-notice-bar color="#aaaaaa" background="none" :left-icon="trumpet" @click="visible = true">
            <span class="inform" v-for="(item, index) in form.msgList" :key="index">{{ item }}</span>
          </van-notice-bar>
          <div class="jine">
            <div class="title">
              {{
                form.loanStatus == 5 ? "Total Pembayaran" : "Pinjaman Maksimum"
              }}
            </div>
            <span>Rp {{ formatNumberWithCommas(form.loanMoney) }}</span>
          </div>
          <div class="productBox">
            <img src="@/assets/img/product_icon.png" alt="" />
            <div class="producName">
              <span>Dana Online</span>
              <div>
                {{
                  form.loanStatus == 5
                    ? "Menunggu pembayaran"
                    : "Datan dan pinjam uang"
                }}
              </div>
            </div>
          </div>

          <!-- 状态1到9 -->
          <div class="jeikaun_xinxi" v-if="form.loanStatus != 5">
            <div class="li_lv">
              <div>Bunga Pinjaman</div>
              <span>{{ form.interestRate }}</span>
            </div>
            <div class="tian_shu">
              <div>Tenor Pinjaman</div>
              <span>{{ form.loanTerm }} Hari</span>
            </div>
          </div>
          <!-- 状态5 -->
          <div class="jeikaun_xinxi" v-else>
            <div class="li_lv">
              <div>Tanggal Pembayaran</div>
              <span>{{ form.interestRate }}</span>
            </div>
            <div class="tian_shu">
              <div>Jumlah Hari Keterlambatan</div>
              <span>{{ form.loanTerm }} Hari</span>
            </div>
          </div>

          <!-- 状态1-9按钮 -->
          <div v-if="form.loanStatus != 5" class="pinjam_uang" @click="goHome">
            Ajukan Sekarang
          </div>
          <!-- 状态5按钮 -->
          <div v-else class="pinjam_uang pinjam_uang5" @click="goHome">
            Pembayaran
          </div>
        </div>
      </div>
      <div class="bottom">
        <div class="productCard" v-for="(item, index) in form.marketList" :key="index">
          <div class="product">
            <img :src="item.product_logo" alt="" />
            <div class="productName">
              <div>{{ item.product_name }}</div>
              <span>{{ item.product_title }}</span>
            </div>
            <div class="iklan" v-if="item.iklan">
              {{ item.iklan }}
            </div>
          </div>
          <div class="juta" v-for="(productItem, productIndex) in item.product_list" :key="productIndex">
            <div v-for="(value, key) in productItem" :key="key">
              <span>{{ key }}:</span>
              <span>{{
                typeof value === "number"
                  ? formatNumberWithCommas(value)
                  : value
              }}</span>
            </div>
          </div>
          <div :class="item.product_click == 1 ? 'text_btn' : 'text_btn_disenable'" :style="{
            background: item.product_status == 5 ? '#f4af5e' : '#1c8ba1',
          }" @click="goOther(item)">
            {{ item.product_button }}
          </div>
        </div>
      </div>
      <div class="advertising" v-show="advertisingShow" @click="goAdvertising">
        <img src="@/assets/img/advertising_close.png" @click="advertisingShow = false" />
      </div>
      <van-dialog v-model="visible" :showConfirmButton="false">
        <div class="dialog">
          <p class="title">{{ form.msgList[0] }}</p>
          <p class="tip">
            {{ form.msgList[1] }}
          </p>
          <button type="button" class="btn" @click="visible = false">
            Setuju
          </button>
        </div>
      </van-dialog>
    </div>
    <van-dialog class="logoutDialog" v-model="visibleLogOut" show-cancel-button cancel-button-text="Batal"
      confirm-button-text="Setuju" @cancel="visibleLogOut = false" @confirm="logOut">
      <h3 class="logout_text">yakin untuk keluar?</h3>
    </van-dialog>
    <van-dialog v-model="visibleHint" :showConfirmButton="false">
      <div class="hint_box">
        <img class="hint" src="@/assets/img/hint.png" alt="" />
        <img class="img_btn" src="@/assets/img/hint_btn.png" alt="" @click="visibleHint = false" />
      </div>
    </van-dialog>
  </van-pull-refresh>
</template>

<script>
import request from '@/utils/request'
import Vue from 'vue'
import { NoticeBar, Dialog, PullRefresh } from 'vant'
import dialogVisible from '@/mixins/dialogVisible'
import trumpet from '@/assets/img/trumpet.png'

import store from '@/store'
import Cookies from 'js-cookie'

Vue.use(NoticeBar)
Vue.use(PullRefresh)

export default {
  name: 'Home1',
  mixins: [dialogVisible],
  components: {
    [Dialog.Component.name]: Dialog.Component
  },
  data () {
    return {
      trumpet: trumpet,
      isLoading: false,
      isRequest: false,
      advertisingShow: true,
      visibleLogOut: false,
      visibleHint: false,
      form: {
        marketList: [],
        msgList: [],
        product_list: []
      },
      upToLoans: []
    }
  },
  created () {
    this.getState()
    const t = this
    // 添加 visibilitychange 事件监听器
    document.addEventListener('visibilitychange', function () {
      if (document.visibilityState === 'visible') {
        // 页面变为可见时执行的逻辑
        t.getState()
      }
    })
  },
  methods: {
    // 下拉刷新
    onRefresh () {
      this.getState()
    },
    // 获取状态
    getState () {
      request.post('marketHomepage').then(res => {
        this.form = res
        this.form.marketList[0].product_list[0]['Jumlah Pinjaman'] = 'Rp8.000.000'
        this.form.marketList[0].product_name = 'TEAD'
        this.form.marketList[0].product_title = 'Pinjaman Online OJK'
        this.form.marketList[0].product_logo = require('@/assets/img/home1_yinliu_logo.png')
        this.form.marketList[0].product_click = 1
        this.form.marketList[0].iklan = 'iklan'
        this.form.marketList[0].appStore_url = 'https://apps.apple.com/id/app/tead-pinjam-tunai-mudah/id6743200553?l=id'
        this.isLoading = false
      })
    },
    // 去首页
    goHome () {
      console.log(this.form)
      if (this.form.switch === 2) {
        this.visibleHint = true
        return
      }
      if (this.form.switch === 1) {
        this.$router.push({
          path: '/home'
        })
      }
    },
    // 跳转到其他项目
    goOther (item) {
      if (this.isRequest) {
        return
      }
      this.isRequest = true
      if (item.appStore_url) {
        this.isRequest = false
        window.location = item.appStore_url
        return
      }
      if (item.switch === 2) {
        this.visibleHint = true
        this.isRequest = false
        return
      }
      const phone = item.jump_url.match(/phone=([^&]*)/)[1]
      // 给定的完整 URL 字符串
      const urlString = item.jump_url
      // 使用字符串截取方法获取不带参数的跳转地址
      let redirectUrl = urlString.split('?')[0]
      // 判断最后一位是否是 '/'
      if (redirectUrl.endsWith('/')) {
        // 去掉最后一位
        redirectUrl = redirectUrl.slice(0, -1)
      }
      // 获取登录信息  UC
      request.post('https://maapi.nyxlyn.cc/queryUserInfo', { phone }).then(res => {
        window.location = `${redirectUrl}/transfer?data=${JSON.stringify(res)}&SSO=1`
        // window.location = `http://localhost:8080/transfer?data=${JSON.stringify(res)}&SSO=1`
      }).finally(() => {
        setTimeout(() => {
          this.isRequest = false
        }, 300)
      })
    },
    // 退出登录
    logOut () {
      store.commit('recordInfo', {
        type: 'userInfo',
        value: {}
      })
      window.localStorage.removeItem('userInfo')
      Cookies.remove('userInfo')
      this.$router.push('/login')
      // 向app发送消息  退出登录
      // const uni = window.uni
      // uni.postMessage({
      //   data: {
      //   }
      // })
    },
    // BRD
    goAdvertising () {
      window.location = `https://www.cashcash.cc/brd?tm=${Date.now()}`
    }
  }
}
</script>
<style scoped lang="less">
.content {
  background: #f0eced;
  min-height: 100vh;

  .advertising {
    height: 90px;
    width: 100%;
    position: fixed;
    bottom: 0;
    background: url("~@/assets/img/advertising_bg.png") no-repeat;
    background-size: 100% 100%;

    img {
      position: absolute;
      width: 28px;
    }
  }
}

.top {
  background: url("~@/assets/img/home1_BG.png") no-repeat;
  background-size: cover;

  // height: 643px;
  .title_img {
    padding-left: 28px;

    .name {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      padding-top: 40px;
      padding-bottom: 25px;
      padding-right: 28px;
      margin-bottom: 15px;

      span {
        font-weight: 600;
        font-size: 34px;
        color: #ffffff;
      }

      span:nth-child(2) {
        position: absolute;
        right: 28px;
      }
    }

    .Semua_orabg_meminjam {
      position: relative;
      padding-bottom: 40px;
      font-size: 0;

      div {
        font-weight: 600;
        font-size: 28px;
        color: #ffffff;
        margin-bottom: 10px;
      }

      span {
        font-weight: 100;
        font-size: 24px;
        color: #ffffff;
      }
    }
  }

  .top_bg {
    position: relative;
    height: 710px;
    background: url("~@/assets/img/product_bg.png") no-repeat;
    background-size: 100% 100%;
    padding-bottom: 30px;

    /deep/ .van-icon__image {
      width: 32px;
      height: 32px;
      margin-right: 10px;
    }

    /deep/ .van-notice-bar {
      height: 78px;
      width: 540px;
      margin: 0 auto;
    }

    .productBox {
      display: flex;
      align-items: center;
      margin-bottom: 24px;
      padding-left: 60px;

      img {
        width: 132px;
        margin-right: 16px;
      }

      .producName {
        span {
          font-weight: 600;
          font-size: 28px;
          color: #333;
        }

        div {
          margin-top: 5px;
          margin-bottom: 10px;
          font-weight: 500;
          font-size: 24px;
          color: #ff7d17;
        }
      }
    }

    .jine {
      margin-left: 90px;
      font-size: 0;

      span {
        display: block;
        font-weight: bold;
        font-size: 60px;
        color: #1c8aa1;
        margin-bottom: 55px;
      }

      .title {
        font-weight: 500;
        font-size: 32px;
        color: #333333;
        margin: 10px 0 15px 0;
      }
    }

    .jeikaun_xinxi {
      display: flex;
      justify-content: space-evenly;
      margin-top: 50px;
      margin-bottom: 50px;
      font-size: 26px;

      .li_lv,
      .tian_shu {
        width: 250px;
        text-align: center;

        div {
          font-weight: 500;
          font-size: 28px;
          color: #aaaaaa;
          margin-bottom: 10px;
        }

        span {
          font-weight: bold;
          font-size: 48px;
          color: #1c8aa1;
        }
      }
    }

    .pinjam_uang {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 auto;
      font-weight: 900;
      font-size: 31px;
      color: #ffffff;
      width: 582px;
      height: 80px;
      background: #1c8aa1;
      border-radius: 72px 72px 72px 72px;
    }

    .pinjam_uang5 {
      background: #f4ae5d;
      border-radius: 72px 72px 72px 72px;
    }
  }
}

.bottom {
  padding: 78px 32px;
  padding-top: 34px;

  .productCard {
    margin-bottom: 30px;
    width: 634px;
    background: #ffffff;
    border-radius: 28px;
    border: 1px solid #e3e3e3;
    padding: 20px;

    .product {
      position: relative;
      display: flex;
      align-items: center;

      img {
        width: 76px;
        height: 76px;
        margin-right: 10px;
      }

      .productName {
        font-size: 22px;

        div {
          font-weight: 500;
          font-size: 28px;
          color: #333333;
          margin-bottom: 8px;
        }

        span {
          font-weight: 400;
          font-size: 24px;
          color: #aaaaaa;
        }
      }

      .iklan {
        position: absolute;
        right: 0;
        top: 0;
        background: #aaaaaa;
        font-size: 24px;
        color: #ffbc09;
        padding: 2px 10px;
        border-radius: 6px;
      }
    }
  }

  .juta {
    margin-top: 20px;
    margin-bottom: 38px;

    div {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 50px;
      padding: 0 20px;
      padding-left: 85px;
      font-weight: 500;
      font-size: 24px;
      color: #7e7e7e;
    }

    div:last-child {
      padding-left: 0;
      margin-top: 20px;

      span:nth-child(1) {
        font-weight: 500;
        font-size: 24px;
        color: #333333;
      }

      span:nth-child(2) {
        font-weight: bold;
        font-size: 32px;
        color: #1c8aa1;
      }
    }
  }

  .text_btn {
    margin: 15px auto;
    margin-top: 0;

    width: 582px;
    height: 80px;
    border-radius: 72px 72px 72px 72px;
    font-weight: 500;
    font-size: 32px;
    color: #ffffff;
    line-height: 80px;
    text-align: center;
    color: #fff;
  }

  .text_btn_disenable {
    .text_btn;
    background: #cdcdcd !important;
  }

  .describe {
    padding: 20px;
    padding-top: 10px;
    background: #f8f8f8;
    font-weight: 400;
    font-size: 26px;
    color: #999999;
    line-height: 37px;
    margin-bottom: 30px;
  }
}

.dialog {
  padding: 40px 30px;

  .title {
    font-size: 50px;
    font-weight: 600;
    margin-bottom: 20px;
  }

  .tip {
    font-size: 35px;
    font-weight: 500;
    margin-bottom: 70px;
  }

  .btn {
    width: 100%;
    height: 100px;
    border-radius: 15px;
    font-size: 40px;
    font-weight: 600;
    color: #fff;
    background: #3a3a3a;
  }
}

.logoutDialog {
  .logout_text {
    // padding: 40px 0;
    color: #000;
    font-size: 46px;
    margin: 60px 0px;
    text-align: center;
  }
}

.hint_box {
  position: relative;

  .hint {
    width: 100%;
  }

  .img_btn {
    position: absolute;
    bottom: 50px;
    left: 50%;
    transform: translateX(-50%);
    width: 500px;
  }
}

.van-dialog {
  background: #fff;
}
</style>
